::-webkit-scrollbar {
  width: 0; /* For Chrome, Safari, and Opera */
}
body {
  background-color: #f9f9f9;
  font-family: "Roboto", sans-serif !important;
  font-weight: 400;
}
.App {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  background-color: #f9f9f9;
}
.arabic-font {
  font-family: "Almarai", sans-serif;
}
.image-container {
  animation: shrink 5s infinite alternate;
}
@keyframes shrink {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

@media (max-width: 767px) {
}
