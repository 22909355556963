.how-to-container {
  display: flex;
  justify-content: center;
  width: 85%;
  height: 65px;
  margin: auto;
  margin-top: 50px;
}
.how-to-head-text-mobile {
  display: none;
}
.icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80%;
  margin: auto;
  margin-bottom: 52px;
}
.icon-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.icon-text {
  text-align: center;
  margin-top: 15px;
  font-size: 24px;
  margin-bottom: 5px;
  color: #1e1e1e;
}
.how-to-head-text {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 45px;
  margin-top: 0;
  width: 85%;
  margin: auto;
  font-weight: 200;
}

.dash {
  display: none;
}
.how-text-color {
  color: #b78364;
  font-weight: 600;
  font-size: 50px;
  text-align: center;
}
.icon {
  width: 70px;
  height: 70px;
}
.text {
  text-align: center;
  font-weight: light;
  color: #333333;
}
@media (max-width: 767px) {
  .how-to-container {
    margin-top: 35px;
    max-height: 40px;
    display: flex;
    justify-content: center;
  }
  .how-to-head-text {
    display: none;
  }
  .how-to-head-text-mobile {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 90%;
    margin: auto;
  }
  .dash {
    width: 40px;
    display: inline-block;
    padding-top: 0px;
    margin-top: 18px;
    /* margin: 0.5rem 0; */
    /* border-style: inset; */
    border-width: 3px;
    opacity: 0.7;
    color: #b78364;
  }
  .how-text-color {
    font-size: 30px;
    margin: 0 5px;
  }
}
