.dark-theme {
  background-color: #e8e7e6;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 60px;
}
.get-estimate {
  font-size: 48px;
  font-family: "Roboto", sans-serif !important;
  font-weight: 600;
  color: #b78364;
}
.scale {
  transition: transform 0.3s;
}
.scale:hover {
  transform: scale(1.2);
}
.details-icons {
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-color {
  color: #b78364 !important;
}
.font-color {
  color: "#1e1e1e";
  font-family: "Roboto", sans-serif;
}
