.about-img {
  width: 300px;
  height: 480px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.about-us-text {
  text-align: left;
}
.about-company-name {
  font-weight: 900;
  color: #b78364;
}
.card-color {
  background-color: #1e1e1e;
}
.card-color:hover {
  background-color: #b78364;
}
@media (max-width: 767) {
  .mobile-card {
    width: 80%;
    margin: auto;
  }
}
